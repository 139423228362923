import { AppComponentBase } from '@shared/app-component-base';
import { Component, OnInit, Input, Injector } from '@angular/core';
import { InspectionServiceProxy, DurableFunctionDto } from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'app-excel-processing-list',
  templateUrl: 'excel-processing-list.component.html'
})
export class ExcelProcessingListComponent extends AppComponentBase implements OnInit {
  @Input() public inspectionId: number;
  @Input() public functionType: 'customer'|'inspected'|'imageLocation'|'span'|'conditions';
  @Input() public messageLabel: String;

  public inspectionDurableFunctions: DurableFunctionDto[] = [];

  public constructor(_injector: Injector,
    private _inspectionService: InspectionServiceProxy) {
    super(_injector);
     // Bind this function as it's used in a callback method so that its internal service call doesn't redirect
    this.downloadDurableFunctionFile = this.downloadDurableFunctionFile.bind(this);
  }

  public ngOnInit(): void {
    this.loadDurableFunctionsList(this.functionType);
  }

  /**
   * Set up the toolbar for the datagrid displaying durable functions so that it can be refreshed
   *
   * @param e
   * @memberof ExcelProcessingListComponent
   */
  public onDurableFunctionToolbarPreparing(e) {
    const me = this;
    e.toolbarOptions.items.unshift({
      location: 'before',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        onClick: me.loadDurableFunctionsList.bind(me, me.functionType)
      }
    });

    me.loadDurableFunctionsList(me.functionType);
  }

  /**
   * Load the list of all durable functions of the requested type for the current inspection from the API
   *
   * @param type A string representing an option for the type of function list to load
   * @memberof ExcelProcessingListComponent
   */
  public loadDurableFunctionsList(type: 'customer'|'inspected'|'imageLocation'|'span'|'conditions') {
    const me = this;

    switch (type) {
      case 'customer':
        me._inspectionService.getCustomerAssetDurableFunctions(me.inspectionId)
          .subscribe(result => {
            me.inspectionDurableFunctions = result;
          });
        break;
      case 'inspected':
        me._inspectionService.getPoletopDurableFunctions(me.inspectionId)
          .subscribe(result => {
            me.inspectionDurableFunctions = result;
          });
        break;
      case 'imageLocation':
        me._inspectionService.getImageLocationDurableFunctions(me.inspectionId)
          .subscribe(result => {
            me.inspectionDurableFunctions = result;
          });
        break;
      case 'span':
        me._inspectionService.getSpanDurableFunctions(me.inspectionId)
          .subscribe(result => {
            me.inspectionDurableFunctions = result;
          });
        break;
      case 'conditions':
        me._inspectionService.getConditionDurableFunctions(me.inspectionId)
          .subscribe(result => {
            me.inspectionDurableFunctions = result;
          });
        break;
      default:
        break;
    }
  }

  /**
   * Call the API to download the excel file associated for the for the clicked durable function row
   *
   * @param $event Object for the download event of an Excel Upload File in the datagrid
   * @memberof ExcelProcessingListComponent
   */
  public downloadDurableFunctionFile($event) {
    const me = this;
    const durableFunction = $event.row.data;

    me.notify.info(`Downloading ${durableFunction.fileName}...`);
    me._inspectionService.downloadDurableFunctionFile(durableFunction.inspectionId, durableFunction.id).subscribe(res => {
      const blob = res.data;
      const link = document.createElement('a');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.setAttribute('href', URL.createObjectURL(blob));
      link.setAttribute('download', res.fileName);
      link.click();
      me.notify.success(`Downloaded ${res.fileName}`);
      document.body.removeChild(link);
      return false;
    });
  }
}

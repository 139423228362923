import { IGoogleSettings } from './interfaces/IGoogleSettings';
import { IMapDefaultSettings } from './interfaces/IMapDefaultSettings'

interface UserManagement {
  defaultAdminUserName: string
}

interface Localization {
  defaultLocalizationSourceName: string
}

interface Authorization{
  encryptedAuthTokenName: string
}

export class AppConsts {
    public static appName: string;
    public static remoteServiceBaseUrl: string;
    public static appBaseUrl: string;
    public static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    public static cacheBuster: string = '?v=' + Date.now();

  public static googleSettings: IGoogleSettings;

  public static localeMappings: any = [];

  public static readonly userManagement: UserManagement = {
      defaultAdminUserName: 'admin'
  };

  public static readonly localization: Localization = {
      defaultLocalizationSourceName: 'AIM'
  };

  public static readonly authorization: Authorization = {
      encryptedAuthTokenName: 'enc_auth_token'
  };

  // Map Default Settings
  public static defaultMapSettings: IMapDefaultSettings;

  // Constant value for asset facility id when the value is null or no value is provided.
  public static noFacilityId: string = 'No Facility Id';
}

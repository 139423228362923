import { ConfigurationServiceProxy } from './configuration-proxy';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from 'abp-ng2-module';

import * as ApiServiceProxies from './service-proxies';
import * as CustomServiceProxies from './custom-service-proxies';

@NgModule({
  providers: [
        CustomServiceProxies.RwcidServiceProxy,
        CustomServiceProxies.UploadHelpGuideServiceProxy,
        CustomServiceProxies.ChangeLogServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.InspectionServiceProxy,
        ApiServiceProxies.CircuitServiceProxy,
        ApiServiceProxies.InspectionTypeServiceProxy,
        ApiServiceProxies.UploadTemplatesServiceProxy,
        ApiServiceProxies.InspectionStatusServiceProxy,
        ApiServiceProxies.PoleServiceProxy,
        ApiServiceProxies.SpanServiceProxy,
        ApiServiceProxies.ImageServiceProxy,
        ApiServiceProxies.CustomAssetPropertyServiceProxy,
        ApiServiceProxies.AuditReasonTypeServiceProxy,
        ApiServiceProxies.ComputerVisionModelServiceProxy,
        ApiServiceProxies.SMEServiceProxy,
        ApiServiceProxies.AnalysisStatusServiceProxy,
        ApiServiceProxies.ArtificialIntelligenceServiceProxy,
        ApiServiceProxies.ComputerVisionModelLabelServiceProxy,
        ApiServiceProxies.InspectionJobTypesServiceProxy,
        ConfigurationServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }

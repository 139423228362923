import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { TokenAuthServiceProxy } from '@shared/service-proxies/service-proxies';

@Injectable()
export class AppAuthService {
    constructor(private _authServiceProxy: TokenAuthServiceProxy) {

    }

    public logout(reload?: boolean): void {
        // call the api and then clear tokens here
        this._authServiceProxy.logout()
          .subscribe(() => {
            abp.auth.clearToken();
            abp.utils.setCookieValue(AppConsts.authorization.encryptedAuthTokenName, undefined, undefined, abp.appPath);
            if (reload !== false) {
              location.href = AppConsts.appBaseUrl;
            }
        });

    }
}

<pagination-template #p="paginationApi"
                     [id]="id"
                     [maxSize]="maxSize"
                     (pageChange)="pageChange.emit($event)">
    <nav>
        <ul class="pagination"
            role="navigation"
            *ngIf="!(autoHide && p.pages.length <= 1)">

            <li [class.disabled]="p.isFirstPage()" *ngIf="directionLinks">
                <a *ngIf="!p.isFirstPage()"
                   (click)="p.previous()">
                  <i class="material-icons">chevron_left</i>
                </a>
                <a *ngIf="p.isFirstPage()">
                  <i class="material-icons">chevron_left</i>
                </a>
            </li>

            <li [class.active]="p.getCurrent() === page.value"
                *ngFor="let page of p.pages">
                <a (click)="p.setCurrent(page.value)">{{ page.label }}</a>
            </li>

            <li [class.disabled]="p.isLastPage()"
                *ngIf="directionLinks">
                <a *ngIf="!p.isLastPage()"
                   (click)="p.next()">
                 <i class="material-icons">chevron_right</i>
            </a>
                <a *ngIf="p.isLastPage()">
                 <i class="material-icons">chevron_right</i>
                </a>
            </li>
        </ul>
    </nav>

</pagination-template>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToTimer'
})
export class SecondsToTimerPipe implements PipeTransform {

  public transform(value: number): string {
    if (isNaN(value) || value == Infinity) {
      return 'Unknown';
    }

    const hours: number = Math.floor((value / 3600));
    value -= hours * 3600;
    const minutes: number = Math.floor(value / 60) % 60;
    value -= minutes * 60;
    const seconds: number = Math.floor(value % 3600 % 60);

    // Unlike minutes and seconds, we can't say for sure that the number of hours
    // will only be 2 digits. If the number of hours is less than 10, we need to
    // prepend a 0 to the hour. For all other amounts, we can display the number
    // of hours. If we would want to display the number of days for some reason
    // at some point, then we could do the same thing that we do for displaying
    // minutes and seconds for hours and perform this for days display.
    let hoursText: String;
    if (hours < 10) {
      hoursText = '0' + hours;
    }

    return hoursText + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
  }
}

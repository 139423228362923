import {
    AfterViewInit,
    Directive,
    ElementRef,
    Injectable,
    HostListener,
    Input,
    SimpleChanges,
    OnChanges
} from '@angular/core';

@Directive({
    selector: '[busy]'
})
@Injectable()
export class BusyDirective {
    @Input('busy') public loading: boolean;
    private $element: JQuery;

    public constructor(private _element: ElementRef) { }
}

/**
 * Checks if the given object is empty
 *
 * @param obj
 * @returns
 */
export function isEmptyObject(obj: any) {
  return Object.keys(obj).length === 0;
}

export function removeItemFromArray(array: any[], item: any) {
  const index = array.indexOf(item);
  if (index > -1) {
    array.splice(index, 1);
  }
}

/**
 * Copies text to the clipboard.
 *
 * Most code used is from:
 * https://stackoverflow.com/questions/45768583/angular-4-copy-text-to-clipboard/45771438#45771438
 *
 * @param text The text to copy to the clipboard.
 * @returns True if the copy was successful. False, otherwise.
 */
export function copyTextToClipboard(text: string): Boolean {
  const textArea = document.createElement('textarea');
  textArea.id = 'txt';
  textArea.style.position = 'fixed';
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.opacity = '0';
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();

  let copySuccessful = false;
  try {
    copySuccessful = document.execCommand('copy');
  } catch (error) {
    console.error('Could not copy to clipboard. Error: ' + error);
  } finally {
    document.body.removeChild(textArea);
  }

  return copySuccessful;
}

/**
 * Appends a html script element with specified source to DOM
 *
 * @param source Source of the script tag
 * @param type Text to put in the type attribute (optional)
 * @param onLoad Function to execute once the script has loaded (optional)
 */
export function addScriptElementToDOM(source: string, type: string = null, onLoad: () => void = null): void {
  const script: HTMLScriptElement = document.createElement('script') as HTMLScriptElement;
  script.src = source;
  if (type != null) {
    script.type = type;
  }
  if (onLoad != null) {
    script.onload = onLoad;
  }
  document.head.appendChild(script);
}

/**
 * Toggles visibility of the specified HTML element based on a boolean value.
 * If true, the element is shown. Otherwise, it is hidden.
 *
 * @param el The element for which to set visibility
 * @param visible Whether the element should be visible
 *
 * @private
 */
export function toggleElementVisibility(el: HTMLElement, visible: boolean): void {
  let displayCssString: 'block'|'none' = 'block';
  if (!visible) {
    displayCssString = 'none';
  }

  if (el) {
    el.style.display = displayCssString;
  }
}

/**
 * Sets the width properties tied to a given lookup in order to allow the dropdown content
 * to expand to fit the width of its listed items
 * @param $event the event passed from an onEditorPreparing
 * @param minWidth the minimum width the lookup's dropdown should shrink to
 * @param maxWidth the maximum width the lookup's dropdown should grow to
 */
export function setDataGridExpandableLookup($event: any, minWidth: number, maxWidth: number): void {
  // Ensure that the field being affected is a dropdown
  const editorName: string = $event.editorName;
  if (editorName == "dxSelectBox") {
    $event.editorOptions.dropDownOptions = {
      width: 'auto',
      minWidth: minWidth + 'px',
      maxWidth: maxWidth + 'px'
    };
  }
}
import { Injector, ElementRef } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import {
  AbpMultiTenancyService,
  FeatureCheckerService,
  LocalizationService,
  MessageService,
  NotifyService,
  PermissionCheckerService,
  SettingService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/session/app-session.service';

export interface Module {
  moduleName: string,
  routerLink: string,
  moduleIcon: string,
  colorClass: string
};

export abstract class AppComponentBase {

    public localizationSourceName: string = AppConsts.localization.defaultLocalizationSourceName;

    public localization: LocalizationService;
    public permission: PermissionCheckerService;
    public feature: FeatureCheckerService;
    public notify: NotifyService;
    public setting: SettingService;
    public message: MessageService;
    public multiTenancy: AbpMultiTenancyService;
    public appSession: AppSessionService;
    public elementRef: ElementRef;

    public constructor(injector: Injector) {
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.elementRef = injector.get(ElementRef);
    }

    public l(key: string, ...args: any[]): string {
        let localizedText: string = this.localization.localize(key, this.localizationSourceName);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, args);
    }

    public isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }
}

import { Component, Injector, AfterViewInit, Input } from '@angular/core';
import { AppComponentBase, Module } from '@shared/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { Router } from '@angular/router';

@Component({
  selector: 'app-module-selector',
  templateUrl: './module-selector.component.html',
  animations: [appModuleAnimation()]
})
export class ModuleSelectorComponent extends AppComponentBase {
  @Input() public moduleList: Module[];

  public constructor(injector: Injector, private _router: Router) {
    super(injector);
  }

  public moduleClicked(module: Module) {
    this._router.navigate([module.routerLink]);
  }

  /**
   * Navigates to the home dashboard.
   */
  public navigateToHomeDashboard(): void {
    this._router.navigate(['']);
  }
}

<div>
  <div>
    <h5 style="display:inline-block">{{messageLabel}}</h5>
    <dx-switch style="margin:20px" #gridSwitch [value]="false"></dx-switch>
  </div>

  <div *ngIf="gridSwitch.value" class="excelDurableFunctionGrid">
    <div>
      <dx-data-grid
        [dataSource]="inspectionDurableFunctions"
        [showBorders]="true"
        (onToolbarPreparing)='onDurableFunctionToolbarPreparing($event)'>
        <dxo-paging [pageSize]="8" [enabled]="true"></dxo-paging>
        <dxo-pager
          [showInfo]="true">
        </dxo-pager>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxi-column dataField="fileName" caption="File Name" [allowHeaderFiltering]="false"></dxi-column>
        <dxi-column dataField="fileExtension" caption="File Extension" width="auto" [allowHeaderFiltering]="false"></dxi-column>
        <dxi-column dataField="status" caption="Status" [allowHeaderFiltering]="false"></dxi-column>
        <dxi-column dataField="numberSucceeded" caption="Rows Succeeded" [allowHeaderFiltering]="false"></dxi-column>
        <dxi-column dataField="numberFailed" caption="Rows Failed" [allowHeaderFiltering]="false"></dxi-column>
        <dxi-column dataField="totalNumber" caption="Total Rows" [allowHeaderFiltering]="false"></dxi-column>
        <dxi-column dataField="durableFunctionCreationTime" caption="Creation Time"
                    dataType="datetime" sortOrder="desc" [allowHeaderFiltering]="false"></dxi-column>
        <dxi-column type="buttons" [width]="60">
          <dxi-button
            hint="Download"
            icon="download"
            [onClick]="downloadDurableFunctionFile"></dxi-button>
        </dxi-column>
      </dx-data-grid>
    </div>
  </div>
</div>
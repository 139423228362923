<!-- Display the items -->
<div class="menuContainer">
  <div class="menuRow">
    <div *ngFor="let module of moduleList">
      <mat-card class='mat-card menuCard {{module.colorClass}}' (click)="moduleClicked(module)">
        <a routerLink="{{module.routerLink}}">
          <div style="text-align: center">
            <span class="{{module.moduleIcon}} fa-3x icon"></span>
            <h3>{{module.moduleName}}</h3>
          </div>
        </a>
      </mat-card>
    </div>
    <!-- In case there is no items but there will be soon -->
    <div *ngIf="moduleList.length === 0" class='col-lg-12 col-md-12 col-xs-12 col-12 fullHeight noGutter'>
      <div class='text-center center-content' style='margin-top: 0px; padding-top: 0px;'>
        <div class='col-lg-4 col-md-6 col-sm-8 col-xs-12 dx-card center-content-card'
          style='padding: 20px;'>
          <div class='fas fa-exclamation-circle fa-3x'></div>
          <h2>
            Nothing here!
          </h2>
          <h4 style='padding-top: 10px;'>
            There are no options here at this time.
          </h4>
          <p>
            Please check again in the future.
          </p>
          <dx-button text='Go to home dashboard' type='default'
              (click)='navigateToHomeDashboard()'></dx-button>
        </div>
      </div>
    </div>
  </div>
</div>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indefiniteArticle'
})
export class IndefiniteArticlePipe implements PipeTransform {
  public transform(value: String): String {
    if (value.trimLeft().startsWith('a ')
      || value.trimLeft().startsWith('an ') || value.trim() === '') {
      return value;
    }

    const letters = ['a', 'e', 'i', 'o', 'u'];
    const firstLetter = value[0].toLowerCase();

    let article: String = 'a';
    if (letters.includes(firstLetter)) {
      article = 'an';
    }

    return `${article} ${value}`;
  }
}

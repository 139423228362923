import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError as _observableThrow,  of as _observableOf } from '@node_modules/rxjs';
import { AppConsts } from '@shared/AppConsts';
import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';

export interface IRwcidResult {
  name: string,
  value: string,
  level: number
}

/**
 * ChangeLogData represents the details of a release note in the change log.
 */
export interface ChangeLogData {
  release: string,
  notes: string[]
}

@Injectable()
export class RwcidServiceProxy {
  private http: HttpClient;
  private _dataFile: string = `./assets/data/rwcid.json${AppConsts.cacheBuster}`;
  public constructor(@Inject(HttpClient) http: HttpClient) {
    this.http = http;
  }
  public getAll(): Observable<IRwcidResult[]> {
    return this.http.get<IRwcidResult[]>(this._dataFile);
  }
}

@Injectable()
export class UploadHelpGuideServiceProxy {
  private http: HttpClient;
  private _conditionUploadDataFile: string = `./assets/data/help/conditionupload/data.json${AppConsts.cacheBuster}`;
  public constructor(@Inject(HttpClient) http: HttpClient) {
    this.http = http;
  }
  public getConditionUploadHelpData(): Observable<any[]> {
    return this.http.get<any[]>(this._conditionUploadDataFile);
  }
}

/**
 * Service for retrieving the change log file.
 */
@Injectable()
export class ChangeLogServiceProxy {
  private http: HttpClient;
  private _changeLogFile: string = `./assets/data/changelog/changeLog.json${AppConsts.cacheBuster}`;
  public constructor(@Inject(HttpClient) http: HttpClient) {
    this.http = http;
  }
  /**
   * Retrieves the change log file.
   *
   * @returns An observable contains the change log data.
   */
  public getChangeLog(): Observable<any[]> {
    return this.http.get<ChangeLogData[]>(this._changeLogFile);
  }
}

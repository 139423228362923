import {Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-abp-pagination-controls',
  templateUrl: './abp-pagination-controls.component.html'
})
export class AbpPaginationControlsComponent {
    @Input() public id: string;
    @Input() public maxSize = 7;

    @Input()
    public get directionLinks(): boolean {
        return this._directionLinks;
    }

    public set directionLinks(value: boolean) {
        this._directionLinks = !!value && value as any !== 'false';
    }

    @Input()
    public get autoHide(): boolean {
        return this._autoHide;
    }

    public set autoHide(value: boolean) {
        this._autoHide = !!value && value as any !== 'false';
    }
    @Input() public previousLabel = 'Previous';
    @Input() public nextLabel = 'Next';
    @Input() public screenReaderPaginationLabel = 'Pagination';
    @Input() public screenReaderPageLabel = 'page';
    @Input() public screenReaderCurrentLabel = `You're on page`;
    @Output() public pageChange: EventEmitter<number> = new EventEmitter<number>();

    private _directionLinks = true;
    private _autoHide = false;
}

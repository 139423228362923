import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'CustomPropertyDatePipe'
})
export class CustomPropertyDatePipe implements PipeTransform {

  private acceptableFormats: string[] = [
    'YYYY-MM-DD',
    'YYYY-MM-DDTHH:mm:ss',
    'YYYY-MM-DDTHH:mm:ssZ',
    'YYYY-MM-DDTHH:mm:ss.SSSSZ'
  ];

  public transform(value: string, defaultValue?: string): string {
    const date: moment.Moment = moment(moment(value, this.acceptableFormats, true).format('l'));
    return (date && date.isValid()) ? date.format('MM/DD/YYYY')
      : value ? value
        : defaultValue;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { AbpModule } from 'abp-ng2-module';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppSessionService } from './session/app-session.service';
import { AppUrlService } from './nav/app-url.service';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { AbpPaginationControlsComponent } from './pagination/abp-pagination-controls.component';
import { LocalizePipe } from '@shared/pipes/localize.pipe';
import { SecondsToTimerPipe } from '@shared/pipes/secondsToTimer.pipe';
import { CustomPropertyDatePipe } from '@shared/pipes/customPropertyDatePipe/customPropertyDate.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { BusyDirective } from './directives/busy.directive';
import { EqualValidator } from './directives/equal-validator.directive';
import { ModuleSelectorComponent } from './module-selector/module-selector.component';
import { ExcelProcessingListComponent } from './components/excelprocessinglist/excel-processing-list.component';
import { DxSwitchModule, DxDataGridModule, DxButtonModule } from 'devextreme-angular';
import { IndefiniteArticlePipe } from '@shared/pipes/indefiniteArticlePipe/indefiniteArticle.pipe';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
    imports: [
        CommonModule,
        AbpModule,
        RouterModule,
        NgxPaginationModule,
        DxSwitchModule,
        DxDataGridModule,
        DxButtonModule,
        // Material Components
        MatDividerModule,
        MatToolbarModule

    ],
    declarations: [
        AbpPaginationControlsComponent,
        LocalizePipe,
        SecondsToTimerPipe,
        IndefiniteArticlePipe,
        CustomPropertyDatePipe,
        BusyDirective,
        EqualValidator,
        ModuleSelectorComponent,
        ExcelProcessingListComponent
    ],
    exports: [
        AbpPaginationControlsComponent,
        ModuleSelectorComponent,
        ExcelProcessingListComponent,
        LocalizePipe,
        SecondsToTimerPipe,
        IndefiniteArticlePipe,
        CustomPropertyDatePipe,
        BusyDirective,
        EqualValidator,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule,
        ScrollingModule,
        DxSwitchModule,
        DxDataGridModule,
        DxButtonModule,
        // Material Components
        MatDividerModule,
        MatToolbarModule
    ]
})
export class SharedModule {
    public static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                AppSessionService,
                AppUrlService,
                AppAuthService,
                AppRouteGuard
            ]
        };
    }
}
